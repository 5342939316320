import React from 'react';
import { Container } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import { selectUser } from '@pzh/crm/src/Modules/auth/selectors';
import { login } from '@pzh/crm/src/Modules/auth/actions';
// import { CREATE } from '@pzh/crm/src/Modules/abilities/actions';
// import { QUESTIONNAIRE_RESOURCE } from '@pzh/crm/src/Modules/api/resources';
// import Can from '@pzh/crm/src/Modules/abilities/components/Can';
// import { Alert, AlertTitle } from '@material-ui/lab';
// import { PDFDownloadLink } from '@react-pdf/renderer';
// import PdfDocumentView from '@pzh/crm/src/Modules/questionnaires/components/pdfDocumentView/PdfDocumentView';
import Questionnaire from '../Modules/questionnaires/components/Questionnaire';
import MainNav from '../Modules/nav/components/MainNav';
import FooterNav from '../Modules/nav/components/FooterNav';

const useStyles = makeStyles((theme) => ({
    '@global': {
        'html, body': {
            height: '100%',
        },
    },

    main: {
        minHeight: '88%',
    },

    version: {
        position: 'absolute',
        right: theme.spacing(1),
    },
}));

const QuestionnairePage = () => {
    const classes = useStyles();
    const user = useSelector(selectUser);
    const dispatch = useDispatch();
    // const questionnairePDFData = useSelector((state) => state.Questionnaire.questionnairePdfData);

    const handleDone = () => {
        dispatch(login());
    };

    return (
        <>
            <MainNav />
            <Container className={classes.main}>
                {/*
                <Can I={CREATE} this={QUESTIONNAIRE_RESOURCE} not>
                    <Alert>
                        <AlertTitle>Abgeschickt!</AlertTitle>
                    </Alert>
                </Can>
               */}
                <Questionnaire
                    contactId={user.contactId}
                    primaryContactId={user.primaryContactId}
                    customerId={user.customerId}
                    onDone={handleDone}
                />
            </Container>
            <FooterNav />
        </>
    );
};

QuestionnairePage.propTypes = {};

QuestionnairePage.defaultProps = {};

export default QuestionnairePage;
