import {
    //   CONTACT_RESOURCE,
    //   MODELS,
    //   QUESTIONNAIRE_RESOURCE,
    USER_RESOURCE,
} from '@pzh/crm/src/Modules/api/resources';
import { apiError } from '@pzh/crm/src/Modules/api/apiSlice';
// import { READ } from '@pzh/crm/src/Modules/abilities/actions';

const tokenUser = (tokenData) => ({
    customerId: tokenData.payload.customer_id,
    contactId: tokenData.payload.contact_id,
    primaryContactId: tokenData.payload.primary_contact_id,
    permissions: {
        abilities: [
            ...tokenData.abilities,
            /*
            { action: READ, subject: CONTACT_RESOURCE },
            { action: READ, subject: MODELS[QUESTIONNAIRE_RESOURCE] },
            {
                action: READ,
                subject: MODELS[QUESTIONNAIRE_RESOURCE],
                fields: ['anamnesisContact'],
                inverted: true,
            },
            */
        ],
    },
});

const authenticate = (api) => (data) => {
    if (data && data.credentials && data.credentials.token) {
        api.setApiKey(data.credentials.token);

        return api[USER_RESOURCE].token().then((resp) => {
            if (!resp.data.name) {
                api.dispatch(apiError('Invalid token'));
                throw new Error('Invalid token');
            }
            const tmp = { ...data, userV3: tokenUser(resp.data) };
            return tmp;
        });
    }

    return Promise.resolve(data);
};

export const attachAuthentication = (api) => {
    api.onAuthenticate(authenticate(api));
};
