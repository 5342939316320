import { attachRestResources } from '@pzh/crm/src/Modules/api/rest';
import { METHODS } from '@pzh/crm/src/Modules/api/resources';
import { createApi } from '@pzh/crm/src/Modules/api/api';
import { RESOURCES } from './resources';
import { getEndpointUrl } from './endpoints';
import { attachAuthentication } from './auth';

export const configureApi = () => {
    const api = createApi();

    attachAuthentication(api);

    attachRestResources(api, RESOURCES, METHODS, getEndpointUrl, (params) => {
        const prepped = { ...params, token: api.getApiKey() };
        if (prepped.id === 'id') {
            delete prepped.id;
        }
        return prepped;
    });

    return api;
};
