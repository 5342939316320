import {
    CONTACT_RESOURCE,
    CUSTOMER_RESOURCE,
    QUESTIONNAIRE_RESOURCE,
    USER_RESOURCE,
    COUNTRY_RESOURCE,
} from '@pzh/crm/src/Modules/api/resources';

export const RESOURCES = [
    {
        name: CONTACT_RESOURCE,
        endpoint: '/contacts',
    },
    {
        name: COUNTRY_RESOURCE,
        endpoint: '/countries',
    },
    {
        name: CUSTOMER_RESOURCE,
        endpoint: '/customers',
    },
    {
        name: QUESTIONNAIRE_RESOURCE,
        endpoint: '/contact_questionnaires',
    },
    {
        name: USER_RESOURCE,
        endpoint: '/users',
        extra: [
            {
                action: 'token',
                endpoint: '/users/token',
                method: 'get',
            },
        ],
    },
];
