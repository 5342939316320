import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';

import reducers from './reducers';

const middleware = (extra) => [thunk.withExtraArgument(extra)];

const enhancers = (extra) => {
    if (process.env.NODE_ENV !== 'production') {
        return composeWithDevTools(applyMiddleware(...middleware(extra)));
    }
    return applyMiddleware(...middleware(extra));
};

const rootReducer = combineReducers(reducers);

const configureStore = (api) => {
    const enhancedCreateStore = compose(enhancers(api))(createStore);

    return enhancedCreateStore(rootReducer, {});
};

export default configureStore;
