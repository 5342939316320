import React from 'react';
import { Container, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import MainNav from '../Modules/nav/components/MainNav';
import FooterNav from '../Modules/nav/components/FooterNav';

const useStyles = makeStyles((theme) => ({
    '@global': {
        'html, body': {
            height: '100%',
        },
    },

    main: {
        minHeight: '88%',
    },

    version: {
        position: 'absolute',
        right: theme.spacing(1),
    },
}));

const UnauthorizedPage = () => {
    const classes = useStyles();

    return (
        <>
            <MainNav />
            <Container className={classes.main}>
                <Typography variant="h1">Ungültiger Link</Typography>
                <Typography variant="body2">
                    Dieser Link ist abgelaufen oder wurde bereits benutzt.
                </Typography>
            </Container>
            <FooterNav />
        </>
    );
};

UnauthorizedPage.propTypes = {};

UnauthorizedPage.defaultProps = {};

export default UnauthorizedPage;
