import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import QuestionnaireForm from '@pzh/crm/src/Modules/questionnaires/components/form/QuestionnaireForm';
import { IdPropType } from '@pzh/crm/src/proptypes/basic';
import LoadingBox from '@pzh/crm/src/Modules/loading/components/LoadingBox';
import {
    useContact,
    useContactsByPrimaryContactId,
} from '@pzh/crm/src/Modules/contacts/contactsSlice';
import { useQuestionnairesByContactId } from '@pzh/crm/src/Modules/questionnaires/questionnairesSlice';
import { Container } from '@material-ui/core';
import { useCanWrite } from '@pzh/crm/src/Modules/abilities/hooks';
// import { useCountries } from '@pzh/crm/src/Modules/countries/hooks';

const Questionnaire = ({ customerId, contactId, primaryContactId, onDone }) => {
    const [contact, { loading: contactLoading, initialized: contactInitialized }] =
        useContact(contactId);
    const [, { loading: primaryContactLoading, initialized: primaryContactInitialized }] =
        useContactsByPrimaryContactId(primaryContactId);
    const [
        questionnaires,
        { loading: questionnaireLoading, initialized: questionnaireInitialized },
    ] = useQuestionnairesByContactId(contactId);

    const loading = contactLoading || questionnaireLoading || primaryContactLoading;
    const initialized =
        (contactInitialized && questionnaireInitialized) || primaryContactInitialized;

    /* always show newest version */
    const [questionnaire] = questionnaires.slice(-1);

    const handleDone = () => {
        if (onDone) {
            onDone();
        }
    };

    const contactWrite = useCanWrite(contact);
    const editContact = useMemo(() => (contactId ? contactWrite : true), [contactWrite, contactId]);

    return (
        <LoadingBox loading={loading} initialized={initialized}>
            <Container maxWidth="lg">
                <QuestionnaireForm
                    customerId={customerId}
                    patientId={contactId}
                    primaryContactId={primaryContactId}
                    editContact={editContact}
                    questionnaireId={questionnaire ? questionnaire.id : null}
                    orientation="vertical"
                    showOutro
                    onDone={handleDone}
                    submitOnLastTab
                />
            </Container>
        </LoadingBox>
    );
};

Questionnaire.propTypes = {
    customerId: IdPropType.isRequired,
    contactId: IdPropType,
    primaryContactId: IdPropType,
    onDone: PropTypes.func.isRequired,
};

Questionnaire.defaultProps = {
    contactId: null,
    primaryContactId: null,
};

export default Questionnaire;
