import React from 'react';
import { Provider as StoreProvider } from 'react-redux';
import SnackbarProvider from '@pzh/crm/src/Components/SnackbarProvider';
import ApiProvider from '@pzh/crm/src/Modules/api/components/ApiProvider';
import AbilityProvider from '@pzh/crm/src/Modules/abilities/components/AbilityProvider';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import theme from '@pzh/crm/src/styles/theme';
import { ThemeProvider } from '@material-ui/core/styles';
import Routes from './Pages/Routes';
import { configureApi } from './Modules/api/api';
import configureStore from './Modules/store/Store';
import ApiErrorHandler from './Modules/api/components/ApiErrorHandler';

const api = configureApi();
const store = configureStore(api);
api.setStore(store);

const App = () => {
    return (
        <ThemeProvider theme={theme}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
                <StoreProvider store={store}>
                    <ApiProvider api={api}>
                        <AbilityProvider>
                            <SnackbarProvider>
                                <ApiErrorHandler />
                                <Routes />
                            </SnackbarProvider>
                        </AbilityProvider>
                    </ApiProvider>
                </StoreProvider>
            </MuiPickersUtilsProvider>
        </ThemeProvider>
    );
};

export default App;
