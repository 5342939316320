import React, { useEffect } from 'react';
import LoadingPage from '@pzh/crm/src/Modules/loading/components/LoadingPage';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { login } from '@pzh/crm/src/Modules/auth/actions';

const LoginPage = () => {
    const { token } = useParams();
    const dispatch = useDispatch();

    useEffect(() => {
        if (token) {
            dispatch(login({ token }));
        }
    }, [token, dispatch]);

    return <LoadingPage loading />;
};

LoginPage.propTypes = {};

LoginPage.defaultProps = {};

export default LoginPage;
