import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    INTERNAL_SERVER_ERROR,
    NOT_FOUND,
    TOO_MANY_REQUESTS,
    UNPROCESSABLE_ENTITY,
} from '@pzh/crm/src/Modules/api/components/ApiErrorHandler';
import { useSnackbar } from '@pzh/crm/src/Modules/snackbar/hooks';
import { selectApiError } from '@pzh/crm/src/Modules/api/selectors';
import { clearApiError } from '@pzh/crm/src/Modules/api/apiSlice';

const ApiErrorHandler = () => {
    const error = useSelector(selectApiError);
    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useDispatch();

    useEffect(() => {
        if (error) {
            if (error.status === NOT_FOUND) {
                /* ignore this error */
                dispatch(clearApiError());
            } else if (error.status === UNPROCESSABLE_ENTITY) {
                enqueueSnackbar('Eingaben fehlerhaft', { variant: 'error' });
            } else if (error.status === TOO_MANY_REQUESTS) {
                enqueueSnackbar('API gedrosselt', { variant: 'error' });
            } else if (error.status === INTERNAL_SERVER_ERROR) {
                enqueueSnackbar('Serverfehler', { variant: 'error' });
            }
        }
    }, [error, dispatch, enqueueSnackbar]);

    return null;
};

ApiErrorHandler.propTypes = {};

export default ApiErrorHandler;
