import React from 'react';
import { AppBar, Grid, Link, Toolbar, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    '@global': {
        'html, body, #root': {
            height: '100%',
            margin: 0,
        },
    },

    root: {
        marginBottom: theme.spacing(4),
        backgroundColor: '#f5f5f5',
        color: '#222',
    },

    logo: {
        height: 80,
        padding: theme.spacing(1),
    },
}));

const MainNav = () => {
    const classes = useStyles();

    return (
        <AppBar position="static" className={classes.root}>
            <Toolbar>
                <Grid container alignItems="center" spacing={2}>
                    <Grid item>
                        <Link href="https://www.pflegezuhause.info/">
                            <img
                                src={`${process.env.PUBLIC_URL}/logo.png`}
                                alt="Pflege zu Hause"
                                className={classes.logo}
                            />
                        </Link>
                    </Grid>
                    <Grid item xs>
                        <Typography variant="h1">
                            Erhebungsbogen zu Ihrer Betreuungssituation
                        </Typography>
                    </Grid>
                </Grid>
            </Toolbar>
        </AppBar>
    );
};

MainNav.propTypes = {};

MainNav.defaultProps = {};

export default MainNav;
