import React from 'react';
import { AppBar, Grid, Link, Toolbar, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import packageJSON from '../../../../package.json';

const useStyles = makeStyles((theme) => ({
    '@global': {
        'html, body, #foot': {
            height: '100%',
            margin: 0,
        },
    },

    foot: {
        backgroundColor: '#f5f5f5',
        height: '10%',
        color: '#222',
    },

    version: {
        position: 'absolute',
        right: theme.spacing(1),
    },
}));

const FooterNav = () => {
    const classes = useStyles();

    return (
        <AppBar position="static" className={classes.foot}>
            <Toolbar>
                <Grid container alignItems="center" spacing={2}>
                    <Grid item>
                        <Typography variant="body1">
                            <Link
                                color="inherit"
                                href="https://www.pflegezuhause.info/anfrage/kontakt/"
                            >
                                Kontakt
                            </Link>
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography variant="body1">
                            <Link
                                color="inherit"
                                href="https://www.pflegezuhause.info/anfrage/impressum/"
                            >
                                Impressum
                            </Link>
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography variant="body1">
                            <Link
                                color="inherit"
                                href="https://www.pflegezuhause.info/anfrage/datenschutz/"
                            >
                                Datenschutz
                            </Link>
                        </Typography>
                    </Grid>
                </Grid>

                <Typography variant="body2" color="textSecondary" className={classes.version}>
                    {packageJSON.version}
                </Typography>
            </Toolbar>
        </AppBar>
    );
};

FooterNav.propTypes = {};

FooterNav.defaultProps = {};

export default FooterNav;
