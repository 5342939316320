import apiReducer from '@pzh/crm/src/Modules/api/apiSlice';
import contactsSlice from '@pzh/crm/src/Modules/contacts/contactsSlice';
import {
    CONTACT_RESOURCE,
    COUNTRY_RESOURCE,
    CUSTOMER_RESOURCE,
    QUESTIONNAIRE_RESOURCE,
} from '@pzh/crm/src/Modules/api/resources';
import questionnairesSlice from '@pzh/crm/src/Modules/questionnaires/questionnairesSlice';
import authSlice from '@pzh/crm/src/Modules/auth/authSlice';
import customersSlice from '@pzh/crm/src/Modules/customers/customersSlice';
import countriesSlice from '@pzh/crm/src/Modules/countries/countriesSlice';

const reducers = {
    api: apiReducer,
    auth: authSlice,

    [CONTACT_RESOURCE]: contactsSlice,
    [CUSTOMER_RESOURCE]: customersSlice,
    [QUESTIONNAIRE_RESOURCE]: questionnairesSlice,
    [COUNTRY_RESOURCE]: countriesSlice,
};

export default reducers;
