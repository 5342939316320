export const getEndpointUrl = (endpoint, action) => {
    if (['index', 'show', 'store', 'update'].includes(action)) {
        return `${endpoint}/form/`;
    }

    if (action === 'token') {
        return endpoint;
    }

    throw new Error(`Invalid action: ${action}`);
};
