import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import { questionnairePath, welcomePath } from './paths';
import QuestionnairePage from './QuestionnairePage';
import PrivateRoute from './PrivateRoute';
import WelcomePage from './WelcomePage';

const Routes = () => (
    <Router basename={process.env.PUBLIC_URL || null}>
        <Switch>
            <Route path={welcomePath} exact component={WelcomePage} />
            <PrivateRoute path={questionnairePath(':token')} component={QuestionnairePage} />
        </Switch>
    </Router>
);

export default Routes;
