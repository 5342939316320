import React from 'react';
import * as PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Route, withRouter } from 'react-router-dom';
import { selectApiError } from '@pzh/crm/src/Modules/api/selectors';
import { TOO_MANY_REQUESTS } from '@pzh/crm/src/Modules/api/components/ApiErrorHandler';
import { selectUser } from '@pzh/crm/src/Modules/auth/selectors';
import UnauthorizedPage from './UnauthorizedPage';
import LoginPage from './LoginPage';

const PrivateRoute = ({ component: Component, role, ...rest }) => {
    const user = useSelector(selectUser);
    const error = useSelector(selectApiError);

    return (
        <Route
            {...rest}
            render={({ location, ...props }) => {
                if (error && ![TOO_MANY_REQUESTS].includes(error.status)) {
                    return <UnauthorizedPage />;
                }

                if (!user) {
                    return <LoginPage loading />;
                }

                return <Component location={location} {...props} />;
            }}
        />
    );
};

PrivateRoute.propTypes = {
    component: PropTypes.oneOfType([PropTypes.func, PropTypes.object]).isRequired,
    role: PropTypes.string,
};

PrivateRoute.defaultProps = {
    role: null,
};

export default withRouter(PrivateRoute);
